/* custom-styles.css */

:root {
    /***************************
  --pf-global--FontFamily--sans-serif: "RedhatDisplay", "Tahoma", "Trebuchet MS", Helvetica, sans-serif;
  --pf-global--FontFamily--heading--sans-serif: "Tahoma", "Trebuchet MS", Helvetica, sans-serif;
   *******************************/

  --ory-theme-font-family: "RedHatDisplay", "Overpass", overpass, helvetica, arial, sans-serif !important;
  --ory-theme-font-family-mono: "RedHatText" "Courier New" !important;
  --ory-theme-font-style: "normal" !important;
}


/* Add other custom styles as needed */
body .merge-styles {
    /*******************
    --pf-v5-c-page__main-section--BackgroundColor: var(--pf-v5-global--palette--blue-50);
    --pf-v5-c-page__sidebar--BackgroundColor: var(--pf-v5-global--palette--blue-600);
    *******************/
}

/* Make the about box image play nicely. */
body .pf-v5-c-about-modal-box__hero {
	display: block;
	visibility: visible;
	background-image: var(--pf-v5-c-about-modal-box__hero--sm--BackgroundImage);
	background-repeat: no-repeat;
	background-attachment: inherit;
	background-position: var(--pf-v5-c-about-modal-box__hero--sm--BackgroundPosition);
	background-size: contain; /* var(--pf-v5-c-about-modal-box__hero--sm--BackgroundSize); */
    background-position: center;
	grid-area: hero;
}

