html,
body,
#root {
  height: 100%;
}

.pf-v5-c-background-image {
  --pf-v5-c-background-image--BackgroundSize: cover !important; 
  --pf-v5-c-background-image--BackgroundPosition: center !important;
}
